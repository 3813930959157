@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    font-family: "Open Sans", Helvetica, sans-serif;
  }

  .date-container {
    font-size: 16px !important;
  }
  .App {
    @apply bg-[#fafafa];
  }
  .page-Item-Container {
    @apply bg-[#fafafa];
  }

  .link-class {
    @apply !text-[#3A6A78]  cursor-pointer;
    text-decoration: underline;
  }

  .pagination {
    @apply flex list-none justify-end;
  }
  .pagination a {
    @apply !py-[3px] !px-[10px] w-[40px] h-[40px] m-[0.3px] border-[0.2px] cursor-pointer;
  }
  .pagination a:hover {
    @apply border-2;
  }
  .activePagination a {
    @apply border-[0.5px] border-[#D0D5DD] !w-[40px] bg-[#F9FAFB];
  }
  .activePage {
    @apply border-[1px] border-[#D0D5DD] !w-[40px] bg-[#F9FAFB];
  }

  .general-text-color {
    @apply !text-[#3A6A78];
  }

  .graph-bg-Color {
    @apply !bg-[#fafafa];
  }
  .relatedObjects-table-bgColor {
    @apply !bg-[#fff];
  }

  .relationship-select .css-m6n4u5-MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: -0.8px !important;
    @apply !mx-[5px] !mb-4 h-7;
  }

  .table-cell-Style {
    @apply border-[#f8f7f7] md:px-4 text-left;
  }

  .table-cell-Style.link-class:hover{
    background-color: #f0f0f0
  }

  .previousButton {
    @apply rounded-l-[8px] border-2;
  }
  .nextButton {
    @apply rounded-r-[8px] border-2;
  }

  .tracking-details {
    @apply rounded-[8px] relatedObjects-table-bgColor py-[10px] border-[1px] w-[98%] md:w-[98%] lg:w-full;
  }
  .backwards-tracking-details {
    @apply rounded-[8px] relatedObjects-table-bgColor py-[10px] border-[1px] w-[98%] md:w-[98%] lg:w-full;
  }
  .vis-network {
    position: relative;
    z-index: 2 !important;
  }

  .date-container .MuiOutlinedInput-root {
    background: #fff !important;
  }
  .date-container [type="tel"] {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .date-container
    .css-yjte5u-MuiFormControl-root-MuiTextField-root
    .MuiOutlinedInput-root,
  .Multi-SelectField-container
    .Multi-SelectField-container
    .css-1aoyl1p-MuiFormControl-root-MuiTextField-root
    .MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    outline: none !important;
  }

  .date-container
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline,
  .Multi-SelectField-container
    .css-1aoyl1p-MuiFormControl-root-MuiTextField-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #3A6A78;
    border-radius: 8px !important;
  }

  .date-container
    .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff;
    background-color: #3A6A78 !important;
  }

  .react-multi-select [type="text"]:focus,
  .Multi-SelectField-container [type="text"]:focus,
  .login-form [type="password"]:focus,
  .login-form [type="text"]:focus,
  .autocomplete-input [type="text"]:focus,
  [type="email"]:focus,
  .password [type="password"]:focus {
    outline: none !important;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: none !important;
    --tw-ring-color: #d0d5dd !important;
    --tw-ring-offset-shadow: none;
  }

  /* Removes the auto background fill that the browser applies to the input fields */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  .objectOverviewTbody th,
  .objectOverviewTbody td,
  .generalInfo-table th,
  .generalInfo-table td,
  .relatedObjectTable td,
  .relatedObjectTable th .sidebar-height td,
  .sidebar-height th,
  .relatedObjectTable th .attributesTable td,
  .attributesTable th,
  .attributesTable td {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 44px !important;
  }

  .attributesTable::-webkit-scrollbar {
    width: 7px;
    scroll-padding: 20px;
  }
  .attributesTable::-webkit-scrollbar-track {
    border-radius: 30px;
  }
  .attributesTable::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 30px;
    height: 10px !important;
  }

  .react-datetime-picker .react-datetime-picker__wrapper {
    border: 1px solid #d0d5dd !important;
    border-radius: 8px;
    padding: 5px;
  }

  .hideNav {
    display: none;
  }

  /* customize sidebar scrollbar */
  ::-webkit-scrollbar,
  .overviewContainer::-webkit-scrollbar,
  .relationLabel::-webkit-scrollbar,
  .object-details::-webkit-scrollbar,
  .right-sidebar::-webkit-scrollbar,
  .node-details::-webkit-scrollbar,
  .edge-details::-webkit-scrollbar,
  .myCustomList::-webkit-scrollbar,
  .autocomplete-input-scroll::-webkit-scrollbar,
  .sidebar-scroll::-webkit-scrollbar {
    width: 7px;
    scroll-padding: 20px;
  }
  ::-webkit-scrollbar-track,
  .overviewContainer::-webkit-scrollbar-track,
  .relationLabel::-webkit-scrollbar-track,
  .object-details::-webkit-scrollbar-track,
  .right-sidebar::-webkit-scrollbar-track,
  .node-details::-webkit-scrollbar-track,
  .edge-details::-webkit-scrollbar-track,
  .myCustomList::-webkit-scrollbar-track,
  .autocomplete-input-scroll::-webkit-scrollbar-track,
  .sidebar-scroll::-webkit-scrollbar-track {
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb,
  .overviewContainer::-webkit-scrollbar-thumb,
  .relationLabel::-webkit-scrollbar-thumb,
  .object-details::-webkit-scrollbar-thumb,
  .right-sidebar::-webkit-scrollbar-thumb,
  .node-details::-webkit-scrollbar-thumb,
  .edge-details::-webkit-scrollbar-thumb,
  .myCustomList::-webkit-scrollbar-thumb,
  .autocomplete-input-scroll::-webkit-scrollbar-thumb,
  .sidebar-scroll::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 30px;
    height: 10px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e5e5e5 !important;
  }

  .config-show::-webkit-scrollbar {
    width: 7px;
    scroll-padding: 2px;
  }

  .config-show::-webkit-scrollbar-track {
    border-radius: 30px;
    margin-top: 20px !important;
  }

  .config-show::-webkit-scrollbar-thumb:vertical {
    background-color: #eaecf0;
    border-radius: 30px;
    height: 2px !important;
  }
  .config-show::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }

  /* levelsScrollHidden */
  .levelsScrollHidden {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .levelsScrollHidden::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    width: 303px !important;
    color: #3A6A78 !important;
  }

  .settings-container .MuiTabs-indicator {
    display: none;
  }

  .settings-container .css-1kica4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #3A6A78 !important;
    background-color: #f5f5ff !important;
    border-radius: 6px;
  }

  .settings-container .css-1kica4j-MuiButtonBase-root-MuiTab-root {
    min-height: 36px !important;
  }

  .settings-container .css-1ujnqem-MuiTabs-root {
    min-height: 36px !important;
  }
  .settings-container .css-1gsv261 {
    border: none !important;
  }
  /* Relationship colors */
  .Article {
    background-color: #f5b1a6;
  }

  .Machine {
    background: #a6d2af;
  }

  .ProductionOrder {
    background: #e4afd7;
  }

  .CurrentlyExecutedProductionOrder {
    background: #fee8db;
  }

  .Product {
    background: #a9b1fa;
  }

  .MachineSpace {
    background: #fadca4;
  }

  .MachineStorageSpace {
    background: #c1ecff;
  }

  .Package {
    background: #acdcec;
  }

  .ResourceList {
    background: #fcedd1;
  }

  .ResourceListSource {
    background: #e8ebee;
  }

  .ResourceListTarget {
    background: #eefeda;
  }

  .StorageSpace {
    background: #cfd3fa;
  }

  .Sensor {
    background: #fad9d3;
  }

  .MachineGroup {
    background: #fdc5e4;
  }

  .SensorGroup {
    background: #d7cac7;
  }

  .Reject {
    background: #fdf6e8;
  }

  /* truncate text */

  .node-text {
    overflow: hidden;
  }

  .node-text span {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.preventSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.rotate {
  transform: rotate(180deg);
}

/*Line Graph styles  */

.axisX text {
  text-anchor: start;
}

.baseGridLine line {
  stroke: rgba(255, 255, 255, 0.5);
}

/* .tooltip{
  fill: black;
} */

.tooltip text {
  font-size: 12px;
  fill: #ffffff;
}
.tooltipSeries text {
  font-size: 12px;
  fill: #ffffff;
}

.tooltipLine {
  stroke: gray;
  stroke-width: 1px;
}
/* .tooltipLineSpecific {
  stroke: #11f546;
  stroke-width: 1px;
} */
.packageStart {
  stroke: #11f546;
  stroke-width: 2;
}
.packageEnd {
  stroke: #d42d08;
  stroke-width: 2;
}

.tooltipContent .contentTitle {
  font-weight: bold;
}

.tooltipLinePoint {
  fill: gray;
}

/*Line Graph style end.*/

/* React Multi select Field style */
.react-multi-select > .select__control {
  border-radius: 8px !important;
  height: 40px !important;
  box-shadow: none !important;
}
.react-multi-select > .select__menu {
  border-radius: 8px !important;
  z-index: 99;
  cursor: pointer !important;
}

.react-multi-select .css-t3ipsp-control {
  outline: none !important;
  border: solid 1px #c4c4c4 !important;
  box-shadow: none !important;
}

.section-holder:first-child {
  margin-top: 0 !important;
}

Button.button-yobi {background-color: #3A6A78;}

Button.button-yobi:hover {background-color: #5a8fa0;}

a.button-yobi {background-color: #3A6A78;}

a.button-yobi:hover {background-color: #5a8fa0;}
